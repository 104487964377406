import { tailwind } from "@theme-ui/presets"

//TODO: figure out how to solve color context (if this can't be solved using parent, need other solution: see primary accessible link colors)

const headerpart = {
  display: "flex",
  px: 4,
  py: 2,
  // height: [tailwind.sizes[24], tailwind.sizes[10], tailwind.sizes[32]],
  zIndex: 2,
  position: "relative",
  // color: 'white',
  alignItems: "center",
  justifyContent: "space-between",
}

const gray = [
  null,
  "#f6f6f6",
  "#e9e9e9",
  "#d0d0d0",
  "#CBD5E0",
  "#a5a5a5",
  "#3f3f3f",
  "#323232",
  "#262626",
  "#151515",
]

// const primary = 'hsl(187.2, 73.22%, 46.86%)'

export default {
  ...tailwind,
  initialColorMode: "dark",
  useColorSchemeMediaQuery: true,
  useCustomProperties: true,
  colors: {
    ...tailwind.colors,
    primary: "hsl(187.2, 73.22%, 46.86%)",
    accessiblePrimary: "hsl(187.2, 73.22%, 46.86%)",
    accent: "#c41230",
    regular: "#3996c9",
    regularbg: gray[8],
    // primary: tailwind.colors.orange[4],
    // accent: tailwind.colors.orange[4],
    secondary: tailwind.colors.indigo[6],
    // accessiblePrimary: '#058294',
    text: tailwind.colors.gray[3],
    contrast: "#262626",
    // contrast: tailwind.colors.gray[9],
    heading: tailwind.colors.white,
    headerbg: gray[8],
    navlinks: gray[2],
    // navcolor:
    background: gray[7],
    // background: `#141821`,
    gray: { ...gray },
    divider: tailwind.colors.gray[8],
    textMuted: gray[5],
    icon_brightest: tailwind.colors.white,
    icon_darker: tailwind.colors.gray[7],
    icon_darkest: tailwind.colors.gray[8],
    icon_red: tailwind.colors.red[8],
    icon_blue: tailwind.colors.blue[6],
    icon_orange: tailwind.colors.orange[5],
    icon_yellow: tailwind.colors.yellow[5],
    icon_pink: tailwind.colors.pink[5],
    icon_purple: tailwind.colors.purple[6],
    icon_green: tailwind.colors.green[5],
    modes: {
      primary: {
        text: tailwind.colors.pink[6],
      },
      light: {
        text: tailwind.colors.gray[8],
        heading: tailwind.colors.black,
        contrast: tailwind.colors.white,
        accessiblePrimary: "#058294",
        headerbg: "white",
        navlinks: gray[8],
        regularbg: "#3996c9",
        // primary: tailwind.colors.orange[7],
        // secondary: tailwind.colors.indigo[2],
        // accent: tailwind.colors.orange[7],
        // background: gray[1],
        background: "#f1f1f1",
        divider: tailwind.colors.gray[2],
        textMuted: gray[6],
        icon_brightest: tailwind.colors.gray[2],
        icon_darker: tailwind.colors.gray[4],
        icon_darkest: tailwind.colors.gray[6],
        icon_green: tailwind.colors.green[8],
        icon_red: tailwind.colors.blue[3],
      },
    },
  },
  breakpoints: ["400px", "600px", "900px", "1200px", "1600px"],
  sizes: {
    ...tailwind.sizes,
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
    screen: "100vw",
  },
  fonts: {
    body: "belizio, serif",
    heading: "belizio, serif",
    links: "benton-sans, sans-serif",
    mono: "Menlo, monospace",
    condensed: "bureau-grot-compressed,sans-serif",
  },
  fontsizes: {
    ...tailwind.fontSizes,
  },
  lineHeights: {
    ...tailwind.lineHeights,
  },
  styles: {
    ...tailwind.styles,
    root: {
      ...tailwind.styles.root,
      // color: `text`,
      backgroundColor: "background",
      fontFamily: "body",
      a: {
        textDecoration: "none",
        // color: "unset",
        transition: "all 0.3s ease-in-out",
        // "&:hover": {
        //   color: `primary`,
        //   textDecoration: `none`,
        // },
      },
    },
    buttons: {
      toggle: {
        color: "background",
        border: "none",
        backgroundColor: "text",
        cursor: "pointer",
        alignSelf: "center",
        px: 3,
        py: 2,
        ml: 3,
      },
      primary: {
        color: "background",
        bg: "primary",
      },
      secondary: {
        color: "background",
        bg: "secondary",
      },
      gray: {
        color: "background",
        bg: "gray",
      },
    },
    Navlinks: {
      a: {
        // color: 'navlinks',
        // color: tailwind.colors.pink[6],
        fontFamily: "links",
        textTransform: "uppercase",
        letterSpacing: "wide",
        fontSize: "12px",
        px: [2, 3],
        py: [2, 3],
        "&:hover": {
          color: "primary",
        },
        "&[data-active='true']": {
          color: "primary",
        },
      },
    },
    Footer: {
      textAlign: "center",
      bg: "headerbg",
      display: "block",
      // position: 'absolute',
      // bottom: 0,
      // color: 'black',
      color: "textMuted",
      px: [2, 3],
      py: [2, 3],
      // fontFamily: 'links',
      // fontSize: '12px',
      fontSize: tailwind.fontSizes[0],
      a: {
        color: "navlinks",
      },
    },
    Container: {
      maxWidth: "5xl",
    },
    p: {
      fontSize: [1, 2],
      letterSpacing: "-0.003em",
      lineHeight: "body",
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: "text",
    },
    blockquote: {
      marginLeft: 0,
      p: {
        fontSize: [2, 3],
        fontWeight: "medium",
        color: "heading",
      },
    },
    h1: {
      ...tailwind.styles.h1,
      fontSize: [6, 7, 8],
      mt: 2,
      mb: 4,
      textShadow: "rgba(255, 255, 255, 0.15) 0px 5px 35px",
      letterSpacing: "tight",
      color: "heading",
    },
    alth1: {
      fontSize: ["30px", "36px", "42px", "60px", "72px"],
      fontWeight: "400",
      lineHeight: "1.1",
      mt: 0,
      mb: 4,
    },
    h2: {
      ...tailwind.styles.h2,
      fontSize: [4, 5, 6],
      mt: 2,
      color: "heading",
      fontWeight: "400",
    },
    h3: {
      ...tailwind.styles.h3,
      fontSize: [3, 4, 5],
      mt: 3,
      color: "heading",
    },
    h4: {
      ...tailwind.styles.h4,
      fontSize: [2, 3, 4],
      color: "heading",
    },
    h5: {
      ...tailwind.styles.h5,
      fontSize: [1, 2, 3],
      color: "heading",
    },
    h6: {
      ...tailwind.styles.h6,
      fontSize: 1,
      mb: 2,
      color: "heading",
    },
  },
  texts: {
    bigger: {
      p: {
        fontSize: [2, 3, 4],
      },
    },
  },
  links: {
    color: "pink",
    navlink: {
      color: "yellow",
      textTransform: "uppercase",
      letterSpacing: "1px",
      fontSize: 1,
      fontWeight: 500,
    },
  },
  layout: {
    header: {
      default: {
        ...headerpart,
        backgroundColor: "headerbg",
        a: {
          color: "navlinks",
        },
        svg: {
          fill: "navlinks",
        },
      },
      transparent: {
        ...headerpart,
        background:
          "linear-gradient(to bottom, rgba(0,0,0,0.45) 0%, transparent 100%)",
        a: {
          color: "white",
        },
        svg: {
          fill: "white",
        },
      },
      noscrim: {
        ...headerpart,
        background: "transparent",
        a: {
          color: "white",
        },
        svg: {
          fill: "white",
        },
      },
      shiny: {
        backgroundColor: tailwind.colors.pink[5],
        svg: {
          fill: "primary",
        },
      },
    },
  },
}

// export default {
//   initialColorMode: "light",
//   colors: {
//     text: "#000",
//     background: "#fff",
//     primary: "#33e",
//     modes: {
//       dark: {
//         text: "#fff",
//         background: "#000",
//       },
//     },
//   },
// }
