import React from "react"
import { ColorMode, Styled } from "theme-ui"
// import theme from "./gatsby-plugin-theme-ui"

export const wrapRootElement = ({ element }) => (
  <>
    <ColorMode />
    <Styled.root>{element}</Styled.root>
  </>
)
